export default {
    "收益率": "Табыс мөлшерлемесі",
    "结束时间": "Аяқталу уақыты",
    "短期融资": "Қысқа мерзімді қаржыландыру",
    "记录": "Жазба",
    "转出本金": "Негізгі соманы аудару",
    "转出收益": "Табысты аудару",
    "钱包余额": "Әмияндағы қалдық",
    "天": "Күндер",
    "请输入用户名": "Пайдаланушы атын енгізіңіз",
    "请输入密码": "Құпиясөзді енгізіңіз",
    "提交": "Жіберу",
    "立即开户": "Тіркелу",
    "联系客服": "Тұтынушыларды қолдау қызметіне хабарласыңыз",
    "联系客服2": "Тұтынушыларды қолдау қызметіне хабарласыңыз",
    "用户名": "Пайдаланушы аты",
    "密码": "Құпиясөз",
    "确认密码": "Құпиясөзді растау",
    "请确认密码": "Құпиясөзді растаңыз",
    "邀请码": "Шақыру коды",
    "请输入邀请码": "Шақыру кодын енгізіңіз",
    "真是姓名(可选)": "Шынайы аты (міндетті емес)",
    "身份证号码(可选)": "ЖСН нөмірі (міндетті емес)",
    "已经有一个帐户": "Сізде шотыңыз бар",
    "版权所有©2024TS股份有限公司保留所有权利": "Авторлық құқық ©2024 TS Co., Ltd. Барлық құқықтар қорғалған",
    "此网站支持IPv6访问": "Бұл веб-сайт IPv6 қосылымын қолдайды",
    "在线用户": "Онлайн пайдаланушылар",
    "快速入口": "Жылдам кіру",
    "支持全球USDT输入": "Жаһандық USDT енгізуді қолдайды",
    "关于我们": "Біз туралы",
    "帮助中心": "Көмек орталығы",
    "市场": "Нарық",
    "产品趋势": "Өнімнің трендтері",
    "订单历史": "Тапсырыс тарихы",
    "我的账户": "Менің шотым",
    "持仓": "Позициялар",
    "今日开业": "Бүгінгі ашылу",
    "昨天收盘": "Кешегі жабылу",
    "最高": "Ең жоғары",
    "最低": "Ең төмен",
    "K线": "K-сызық",
    "趋势": "Тренд",
    "挂单": "Күту парағы",
    "数量": "Саны",
    "买涨价": "Бағаның өсуіне сатып алу",
    "买跌价": "Бағаның төмендеуіне сатып алу",
    "时间": "Уақыт",
    "去向": "Бағыт",
    "买涨": "Өсуге сатып алу",
    "买跌": "Төмендеуге сатып алу",
    "持仓详情": "Позицияның егжей-тегжейі",
    "结算时间": "Есеп айырысу уақыты",
    "购买时间": "Сатып алу уақыты",
    "盈利": "Пайда",
    "没有更多数据": "Қосымша деректер жоқ",
    "购买确认": "Сатып алуды растау",
    "名称": "Аты",
    "方向": "Бағыт",
    "当前价格": "Ағымдағы баға",
    "账户余额": "Шоттағы қалдық",
    "秒": "Секунд",
    "最小购买金额等于或大于": "Минималды сатып алу сомасы",
    "收益": "Табыс",
    "取消": "Бас тарту",
    "确定": "Растау",
    "持仓中": "Позицияда",
    "历史持仓": "Тарихи позициялар",
    "利润/损失/时间": "Пайда/Залал/Уақыт",
    "产品/金额": "Өнім/Сома",
    "加载中": "Жүктелуде",
    "充值": "Толықтыру",
    "提款": "Ақшаны шығару",
    "财务详细信息": "Қаржылық мәліметтер",
    "实名认证": "Жеке басын растау",
    "提款历史记录": "Ақша шығару тарихы",
    "订单历史记录": "Тапсырыс тарихы",
    "我的团队": "Менің командам",
    "邀请": "Шақыру және бөлісу",
    "用户等级": "Пайдаланушы деңгейі",
    "杠杆作用": "Иық",
    "修改密码": "Құпиясөзді өзгерту",
    "切换语言": "Тілді өзгерту",
    "退出登录": "Шығу",
    "存入市场": "Нарыққа салу",
    "充值数量": "Толықтыру сомасы",
    "登录密码": "Кіру құпиясөзі",
    "提款密码": "Ақша шығару құпиясөзі",
    "首次设置时，请输入4位提款密码": "Алғаш рет орнатқанда, 4 таңбалы құпиясөзді енгізіңіз",
    "请重新输入4位数字密码": "4 таңбалы құпиясөзді қайта енгізіңіз",
    "新密码": "Жаңа құпиясөз",
    "请仔细检查您的信息": "Ақпаратыңызды мұқият тексеріңіз",
    "银行名称": "Банк атауы",
    "银行代码": "Банк коды",
    "银行账户": "Банк шоты",
    "持卡人": "Карта иесі",
    "银行分行": "Банк бөлімшесі",
    "提款数量": "Ақша шығару сомасы",
    "请输入提款数量": "Ақша шығару сомасын енгізіңіз",
    "友情提示": "Достық кеңес",
    "印花税": "Мөр салығы",
    "如果你有任何问题，请": "Егер сұрақтарыңыз болса,",
    "时间": "Уақыт",
    "过滤": "Сүзгі",
    "开始": "Бастау",
    "结束": "Аяқтау",
    "请选择时间": "Уақытты таңдаңыз",
    "交易类型": "Мәміле түрі",
    "投资结算": "Инвестицияны есеп айырысу",
    "在线充值": "Онлайн толықтыру",
    "提款退款": "Ақшаны қайтару",
    "投资支出": "Инвестициялық шығындар",
    "提款申请": "Ақша шығару өтініші",
    "提款成功": "Ақша шығарылды",
    "重置": "Қалпына келтіру",
    "实名制验证": "Ресми аты бойынша тексеру",
    "匿名用户": "Анонимді пайдаланушы",
    "真实姓名": "Толық аты",
    "请输入真实姓名": "Толық атыңызды енгізіңіз",
    "身份证号码": "ЖСН нөмірі",
    "请输入身份证号码": "ЖСН нөмірін енгізіңіз",
    "邀请二维码": "QR кодпен шақыру",
    "复制邀请链接": "Сілтемені көшіру",
    "复制成功": "Сәтті көшірілді",
    "下属人数": "Төменгі сатыдағы адамдар саны",
    "团队规模": "Команда мөлшері",
    "团队余额": "Команда балансы",
    "团队帐户更改": "Команда шотының өзгерісі",
    "团队投资": "Команда инвестициясы",
    "团队报告": "Команда есебі",
    "状态": "Күй",
    "未解决": "Шешілмеген",
    "稳定的": "Тұрақты",
    "概述": "Жалпы шолу",
    "总余额": "Жалпы қалдық",
    "投资": "Инвестиция",
    "盈利能力": "Пайда әкелу қабілеті",
    "存取款差异": "Депозит/Шығарудың айырмашылығы",
    "总损益": "Жалпы пайда/залал",
    "退出成功": "Шығу сәтті болды",
    "请先完成实名认证": "Алдымен жеке басын растауды аяқтаңыз",
    "绑定银行卡": "Банк картасын байлау",
    "请输入4位数字密码以验证您的身份": "Жеке басты растау үшін 4 таңбалы құпиясөзді енгізіңіз",
    "请绑定银行卡": "Банк картасын байлаңыз",
    "收入": "Кіріс",
    "支出": "Шығыс",
    "价格": "Баға",
    "此功能尚未打开": "Бұл функция әлі қосылмаған",
    "固定": "Тұрақты",
    "操作": "Операция",
    "详情": "Толығырақ",
    "复制失败": "Көшіру сәтсіз аяқталды",
    "切换成功": "Ауыстыру сәтті болды",
    "网上借款": "Онлайн несие",
    "公告": "Хабарлама",
    "银行卡": "Банк картасы",
    "请选择银行": "Банкті таңдаңыз",
    "请填写充值金额": "Толықтыру сомасын енгізіңіз",
    "提款地址": "Ақша шығару мекенжайы",
    "请输入提款地址": "Мекенжайды енгізіңіз",
    "您还未绑定提款地址，请先绑定提款地址!": "Сізде ақша шығару мекенжайы жоқ, алдымен оны байлаңыз!",
    "USDT地址": "USDT мекенжайы",
    "应转USDT金额": "USDT аудару сомасы",
    "客服": "Қолдау қызметі",
    "请先设置提款密码": "Алдымен ақша шығару құпиясөзін орнатыңыз",
    "余额": "Қалдық",
    "格式不正确": "Формат дұрыс емес",
    "取款支出": "Ақша шығару шығыны",
    "取款退回": "Ақша қайтарылды",
    "充值成功": "Толықтыру сәтті аяқталды",
    "上次交易": "Алдыңғы мәміле",
    "APP下载": "Қосымшаны жүктеу",
    "佣金": "Комиссия",
    "账户余额R50000以下，1:1配比": "R50000-нан төмен теңгерім үшін, 1:1 қатынас",
    "账户余额R50001-R100000，1:2配比": "R50001-R100000 теңгерім үшін, 1:2 қатынас",
    "账户余额R100001-R200000，1:3配比": "R100001-R200000 теңгерім үшін, 1:3 қатынас",
    "账户余额R200001-R500000，1:4配比": "R200001-R500000 теңгерім үшін, 1:4 қатынас",
    "账户余额R500000以上，1:5配比": "R500000-нан жоғары теңгерім үшін, 1:5 қатынас",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "Мысалы, егер сіздің шотыңызда R100000 болса, платформа 1:2 қатынаста R200000 толықтырады, осылайша сіздің инвестициялық капиталыңыз R300000 болады",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣金": "45 күндік келісімшартты аяқтағаннан кейін, платформаға негізгі соманы қайтару кезінде 10% пайда комиссиясын төлеу қажет",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "Шот қалдығы негізгі соманың 30%-на дейін төмендегенде, жүйе келісімшартты автоматты түрде бұзады",
    "说明": "Сипаттама",
    "点击参与": "Қатысу үшін басыңыз",
    "参与成功": "Қатысу сәтті аяқталды",
    "已参与": "Қатысты",
    "正面": "Алдыңғы бет",
    "反面": "Артқы бет",
    "请上传证件": "Құжаттарды жүктеңіз",
    "系统错误，请稍后尝试！": "Жүйе қатесі, кейінірек қайталап көріңіз!",
    "全民代理": "Баршаға агенттік",
    "区域总代理—需要拥有多年品牌推广经验，以及管理能力，需经平台审核资质并缴纳50万美金的保证金,可享受代理团队总收益的2%": "Аймақтық негізгі агент — брендті жылжыту бойынша тәжірибесі және басқару дағдылары бар, платформамен мақұлданған, сондай-ақ 500,000 АҚШ долларын кепілдікке төлеген, агенттік командасының жалпы табысының 2%-ын алуға құқылы",
    "一级代理人，享受下级收益的10%": "1-деңгей агенті төменгі деңгейдегі табыстың 10%-ын алады",
    "二级代理人，享受下级收益的5%": "2-деңгей агенті төменгі деңгейдегі табыстың 5%-ын алады",
    "三级代理人，享受下级收益的3%": "3-деңгей агенті төменгі деңгейдегі табыстың 3%-ын алады",
    "投资币": "Инвестициялық валюта",
    "累计理财收益": "Жалпы жинақталған қаржылық табыс",
    "凭证": "Құжат",
    "请先完成实名认证": "Алдымен жеке басын растауды аяқтаңыз",
    "请上传凭证": "Құжатты жүктеңіз",
    "只有完成一个周期的订单后，才可以继续另外一个新的订单": "Тек бір циклдік тапсырысты аяқтағаннан кейін жаңа тапсырысты бастауға болады",
    "转账到金融账户后，每天至少需要20个小时才能产生收入": "Қаржылық шотқа аударым жасағаннан кейін, табыс алу үшін күн сайын кемінде 20 сағат қажет",
    "财务管理收入将在财务管理期结束后提取，财务管理金额在此期间不能提取": "Қаржылық басқарудан табыс тек қаржылық басқару мерзімі аяқталғаннан кейін ғана алынады, осы кезеңде қаражат алу мүмкін емес",
    "未认证": "Расталмаған",
    "待审核": "Қарастырылуда",
    "已认证": "Расталған",
    "已驳回": "Қабылданбаған",
    "提示": "Кеңес",
    "登录": "Кіру",
    "身份证/护照正面": "Жеке куәлік/паспорттың алдыңғы жағы",
    "身份证/护照反面": "Жеке куәлік/паспорттың артқы жағы",
    "体验金": "Сынақ қоры",
    "金额": "Сома",
    "未领取": "Алынбаған",
    "领取中": "Алу процесі жүріп жатыр",
    "已过期": "Мерзімі өтіп кеткен",
    "领取时间": "Алу уақыты",
    "过期时间": "Мерзімі біту уақыты",
    "领取": "Алу",
    "提款请绑定": "Шығаруды іске асыру үшін байланыстырыңыз",
    "最低提款金额": "Ең төменгі алу сомасы",
    "下级返佣记录": "Төменгі деңгейдегі комиссияны қайтару жазбасы"
}
